import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, IconButton, LinearProgress, LinearProgressProps, Skeleton, Typography } from '@mui/material';
/* import CopyToClipboard from 'react-copy-to-clipboard'; */
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
/* mport DeleteDialog from '../Dialogs/DeleteDialog'; */
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Moment from 'moment';
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" /* color="text.secondary" */>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export default function WgConnection(props: any) {


    var a = Moment(props.item.Date)
    const formatDate = Moment(props.item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')


    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    const [progress, setProgress] = React.useState(70);

    return (

        <div>

            <Grid container spacing={2} mb={1}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"


                    >


                        {/*  <IconButton
                            className='pay_button'
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                            onClick={() => { navigate("/edit", { state: { id: props.item.Id, name: props.item.Name?.String == "" ? "empty" : props.item.Name?.String } }) }}
                        >
                            <EditIcon />
                        </IconButton > */}


                        {/*       <IconButton
                            className='pay_button'
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                            onClick={() => {
                                props.setLoadingState(); props.onChangeProtocClick(props.item.Id, props.item.OnMulticonnection,
                                    props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId), props.item.TariffId.Int64)
                            }}
                        >
                            <RefreshIcon />
                        </IconButton > */}
                        {/* 
                        {(props.data.length > 1) ?
                            <IconButton
                                className='pay_button'
                                size="small"

                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                onClick={() => { props.setLoadingState(); props.onDeleteOpenClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : <div></div>
                        } */}

                    </Grid>


                </Grid>

            </Grid>


            {/*             <Typography>Сервер недоступен. Необходимо обновить подключение</Typography>
 */}
            {/*   <Grid container spacing={2} mb={2}>
                <Grid item xs={5}>
                    <Typography textAlign={"start"}>Сервер</Typography>
                </Grid>
                <Grid item xs={7} >
                    <Typography textAlign={'right'}>{props.item.Domain}</Typography>
                </Grid>
            </Grid> */}


            <div>

          {/*       <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>_________</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.Code}</Typography>
                    </Grid>
                </Grid> */}
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>Internal ID</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.InternalId.String?.toString()}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>ID</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.Id}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>Дата создания</Typography>
                    </Grid>


                    <Grid item xs={7}>

                        <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                    </Grid>
                </Grid>


                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>Регион</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        {
                            props.item.Flag?.String == ""
                                ? <Typography textAlign={'right'}> Регион вычислен автоматически</Typography>
                                : <Typography textAlign={'right'}><i className={"em em-flag-" + props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {props.item?.City?.String}</Typography>
                        }

                    </Grid>
                </Grid>



                {/*        <Grid container spacing={2} mb={2}>
<Grid item xs={5}>
<Typography>Регион</Typography>
</Grid>
<Grid item xs={7}>
<Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item.City?.String}</Typography>
</Grid>
</Grid> */}

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>Сервер</Typography>
                    </Grid>
                    <Grid item xs={7} >
                        <Typography textAlign={'right'}>{props.item.Domain}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Typography textAlign={"start"}>Статус</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography textAlign={'right'}>{props.item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    flexDirection={"column"}
                    /*  justifyContent="center"
                     alignItems="center" */
                    sx={{
                        borderRadius: '12px'
                    }}

                >{/* 
<CopyToClipboard text={item.Code}
    onCopy={() => setState({ copied: true })}> */}

               {/*      <Box onClick={() => test(props.item.Id)} bgcolor={'#EFEFEF'} style={{
                        backgroundColor: '#EFEFEF', borderRadius: '12px', display: 'flex',
                        justifyContent: 'space-between', alignItems: 'center'
                    }} p={2} className={props.tg != undefined && props.tg.colorScheme != 'light' ? '' : '_link_box'}>


                        <Grid container onClick={onCopyClick} style={{
                            background: '#EFEFEF'
                        }} >
                            <Grid item xs={10} style={{
                                background: '#EFEFEF'
                            }}>

                              

                                <Typography style={{ color: '#1E88E5', textAlign: 'left', wordWrap: 'break-word' }}>
                                    {props.item.Code}
                                </Typography>



                            </Grid>
                            <Grid item xs={2} textAlign={"right"} style={{ color: '#1E88E5' }}>
                                <OpenInNewIcon></OpenInNewIcon>
                            </Grid>
                        </Grid>
                  
                    </Box> */}
                    {/*  </CopyToClipboard> */}
                    {
                        !isOpen
                            ? <div></div>
                            : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>Скопировано</Typography>
                    }

                    {/*  <div>{item.Code} </div> */}
                    {/*  <Typography >{item.Code}</Typography> */}
                </Box>

            </div>

        </div>

    );
}