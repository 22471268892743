import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Avatar, Box, Button, ButtonGroup, CircularProgress, Divider, Grid, IconButton, InputBase, Link, Paper, Skeleton, Stack, TextField, styled } from '@mui/material';
import Moment from 'moment';
/* import "./styles.css" */
import AddIcon from '@mui/icons-material/Add';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ComputerIcon from '@mui/icons-material/Computer';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';

import { Edit } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUrl } from '../auth/getUrl';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import AlertDialog from './AlertDialog';
import DeleteDialog from './DeleteDialog';
import PublicIcon from '@mui/icons-material/Public';
import OutlineConnection from './Connections/OutlineConnection';
import TmConnection from './Connections/TmConnection';
import PcConnection from './Connections/PcConnection';
import WgConnection from './Connections/NoneConnection';
import NoneConnection from './Connections/NoneConnection';

export default function User(props: any) {


    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [isLoading, setIsLoading] = React.useState(false);
    const params = useParams();

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };


        var getStatusSymbol = (enabled: Boolean, confId: any, isLink: Boolean, internalId: any, code: string, ping = false, type: number) => {

        
            if (confId != 0) {
                return enabled ? <ComputerIcon style={{ color: 'green', paddingRight: 5 }}></ComputerIcon> : <ComputerIcon style={{ color: 'red', paddingRight: 5 }}></ComputerIcon>
            }
    
    
    
            if (isLink) {
    
    
                if (type == 41) {
                    return <PublicIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></PublicIcon>
                }
                else if (type == 42) {
                    return <ComputerIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></ComputerIcon>
                }
                else if (ping || internalId == "") {
                    return <LinkIcon style={{ color: 'black', paddingRight: 5 }}></LinkIcon>
    
                }
                else {
                    return enabled ? <LinkIcon style={{ color: 'green', paddingRight: 5 }}></LinkIcon> : <LinkIcon style={{ color: 'red', paddingRight: 5 }}></LinkIcon>
                }
            }
    
            if (code == "none") {
                return <QrCodeIcon style={{ color: 'black', paddingRight: 5 }}></QrCodeIcon>
            }
    
            return enabled ? <QrCodeIcon style={{ color: 'green', paddingRight: 5 }} ></QrCodeIcon> : <QrCodeIcon style={{ color: 'red', paddingRight: 5 }}></QrCodeIcon>
            // return enabled ? "🟢" : "🔴"
    
        }
    

    var getStatusName = (enabled: Boolean, id: number, name: string) => {

        if (name == "") {
            return "Подключение #" + id
        }
        else {
            return name
        }

        //  return enabled ? <QrCodeIcon style={{color:'green',  paddingRight:5}} ></QrCodeIcon> : <QrCodeIcon style={{color:'red', paddingRight:5}}></QrCodeIcon> 
        // return enabled ? "🟢" : "🔴"

    }

    var returnToNewCodeForm = () => {

        getUrl("/api/ext_create_outline?token=cbd47545441b7ab0d456b99ce38682d3&id=" + params.id)
            /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
            .then(res => {
                /*  props.refresh() */

                if (res.status == 401) {
                    props.setToken(false)
                    return;

                }

                window.location.reload();
            }, error => {
                window.location.reload();
            })

        /*  onChangeProtocClick(id, onMulticonnection, userId, serverTypeId == 7 || serverTypeId == 40)  */
        /*   navigate("/new", { state: { info: props.info, conn: conn } }) */

        /*     navigate("/new", { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } })  */
        /* { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } }) } */
    }

    var onDeleteClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
        setCurrentConn(id)
        setOpen2(true)
    }






    const handleClientsDownload = async (response: any) => {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response));
        element.setAttribute('download', `your_file_name.txt`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const BootstrapButton = styled(Button)({
        borderBottom: '1px solid !important',
        borderRadius: '0px',
        padding: '0px',

        marginBottom: '5px',
        color: '#ffc107',//props.tg.themeParams.text_color ?? '#1E88E5',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '0,85rem',
        backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',

        '&:hover': {
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    });

    const [isOpen, setIsOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    var onCopyClick = (event: any) => {
        setState({ value: 'dddsd', copied: false })
        setAnchorEl(event.currentTarget);
        setIsOpen(true)
    }

    const [open1, setOpen1] = React.useState(false);

    const [currentConn, setCurrentConn] = React.useState(-2);
    const [isOutline, setIsOutlinen] = React.useState(false)

    const [isMulti, setIsMulti] = React.useState(false);

    const [isOk, setisOK] = React.useState(false);

    const [isLoadingButton, setIsLoadingButton] = React.useState(false);
    const [userId, setUserIs] = React.useState("")
    //const current_user = 234051811
    const [text, setText] = React.useState(`Выбранное подключение будет удалено.
    Вместо него будет создано новое подключение от WhyPN на базе ShadowSocks.`);


    var onChangeProtocClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {

        /*          
                if (props.balance > 0) { */
        setCurrentConn(id)
        setOpen1(true)
        /*   }
          else {
              navigate("/pay_refresh")
          } */
    }

    const [conn, setConn] = React.useState([]);
    const [ip, setIp] = React.useState(null);
    /*  const [isLoading, setIsLoading] = React.useState(true); */
    React.useEffect(() => {
        setIsLoading(true)
        getUrl("/api/connections_by_id?" + "id=" + params.id)
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                                 var conns = result
                    setIsLoading(false)
                    setConn(result);


                    /*  getUrl("/api/ip")
                         //fetch("http://localhost:5010/api/ip")
                         .then(res => res.json())
                         .then(
                             (result) => {
                                 setIsLoading(false)
                                 setIp(result)
                             },
                             (error) => {
    
                             }
                         ) */
                })

    }, [])

    const [open2, setOpen2] = React.useState(false);

    var onDeleteOpenClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
        setIsOutlinen(isOutline)
        setCurrentConn(id)
        setIsMulti(isMulti)
        setOpen2(true)
    }

    var onDeleteClickClose = () => {
        setOpen2(false)
    }

    var onDeleteOpenApprove = (isOutline: any, conn: any) => {


         

        if (isOutline) {
            getUrl("/api/remove_outline?id=" + conn)
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
                .then(res => {
                    /*  props.refresh() */

                    if (res.status == 401) {
                        props.setToken(false)
                        return;

                    }

                    window.location.reload();
                }, error => {
                    navigate("/login")
                })
                ;
        }


        else {
            /*    fetch("http://localhost:5010" + '/api/remove?id=' + id) */
            getUrl("/api/remove?id=" + conn)
                .then(res => {

                    if (res.status == 401) {
                        props.setToken(false)
                        return;

                    }
                    /*   props.refresh() */
                    window.location.reload();
                }, error => {
                    navigate("/login")
                }
                );
        }

        setOpen2(false)
    }

    var test = (ss: string) => {

        /*  const winUrl = URL.createObjectURL(
             new Blob([getHtmlToRedirect(ss)], { type: "text/html" })
         ); */

        var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";

        window.open(getUrl("/api/openss", props.tg) + "id=" + ss)

        /*   fetch(getUrl("/api/openss", props.tg) + "id=" + currentConn)
          .then(response => {
  
               
              let responseHtml = (response as any).data;
              console.log(responseHtml, "Monitoring");
             //open the new window and write your HTML to it
              var myWindow = window.open("", "response", "resizable=yes");
              (myWindow as any).document.write(responseHtml);
  
            
             // window.location.reload();
          }, error => {
             // window.location.reload();
          }
          ); */

        /*   window.open(
              getHtmlToRedirect(ss), '_blank', windowSize
          );
      */

        /* let newWindow = window.open();
        (newWindow as any).document.write( getHtmlToRedirect(ss),); */
        //window.open("https://whypn.com/testify02.html")
        /*  (child as any).document.write(getHtmlToRedirect(ss));
         (child as any).document.close(); */
        /*   var __html = require('./template.html');
          var template = { __html: __html }; */

        /*  return React.createElement("h1", {dangerouslySetInnerHTML: {__html: html}}) */

        /* const winHtml = `<!DOCTYPE html>
        <html>
            <head>
                <title>Window with Blob</title>
            </head>
            <body>
                <h1>Hello from the new window!</h1>
            </body>
        </html>`;
    
    const winUrl_ = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
    );
    
    
    const win = window.open(
       winUrl_,
        "win",
        `width=800,height=400,screenX=200,screenY=200`
    ); */
    }

    const handleClickOpen = () => {
        setIsLoadingButton(true)

        fetch(getUrl("/api/refresh", props.tg) + "conn_id=" + userId + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : userId))

            .then(res => {
                //  props.refresh()
                 
                if (res.status == 402) {
                    redirect("/pay")
                }
                setIsLoadingButton(false)
                setisOK(true)
                setText("Подключение успешно обновлено")
                // setOpen1(false);



            }, error => {
                window.location.reload();
            })
            ;


    };

    const handleClose = () => {
        setOpen1(false);
        // window.location.reload();
    };


    const onChangeOpenApprove = () => {
        setOpen1(false);
        window.location.reload();
    };




    const changeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserIs(event.target.value);
    }

    const onSearchClick = () => {
        setIsLoading(true)
        getUrl("/api/connections_by_id?" + "id=" + userId)
            //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
            .then(res => res.json())
            .then(
                (result) => {

                     
                    var conns = result
                    setIsLoading(false)
                    setConn(result);


                    /*  getUrl("/api/ip")
                         //fetch("http://localhost:5010/api/ip")
                         .then(res => res.json())
                         .then(
                             (result) => {
                                 setIsLoading(false)
                                 setIp(result)
                             },
                             (error) => {
    
                             }
                         ) */
                })
    }

    return (

        <Box style={{ marginTop: 10 }}>

            {/*             <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >

                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Id пользователя"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={changeUserId}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onSearchClick}>
                    <SearchIcon />
                </IconButton>
   
            </Paper> */}


            {
                isLoading
                    ? <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>

                        <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: '#1E88E5' }} />
                    </Box>

                    :
                    <Box>

                        <AlertDialog dialog_open={open1} handleClickOpen={handleClickOpen} handleClose={handleClose} handleApprove={onChangeOpenApprove} isOk={isOk} text={text} isLoading={isLoadingButton} tg={props.tg}></AlertDialog>
                        {/*                             <DeleteDialog dialog_open={open2} handleClickOpen={onDeleteOpenClick} onDeleteClickClose={onDeleteClickClose} handleApprove={onDeleteOpenApprove()} isOk={isOk} isLoading={isLoadingButton} tg={props.tg}></DeleteDialog>
 */}                            {conn != null
                            ? (conn as any).IsEmpty == true
                                ? <div>{/* <NewUserPay tg={props.tg}></NewUserPay> */}jijijiji</div>

                                : <Box sx={{ mt: 3, p: 2 }} style={{ background: 'white', borderRadius: '12px', boxShadow: 'none' }} className='test_1'
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 1
                                    }}>
                                        <Typography style={{ color: '#ffffff' }}>Подключения</Typography>
                                        <Avatar
                                            style={{ zIndex: 200 }}
                                            onClick={returnToNewCodeForm}
                                            variant="rounded"
                                            sx={{

                                                backgroundColor: ' #ffc107;',
                                                color: 'white',
                                                width: 30, height: 30
                                            }}
                                        >
                                            <AddIcon fontSize="inherit" />
                                        </Avatar>
                                    </Box>
                                    < Divider style={{ color: '#edeef0' }}></Divider>


                                    {

                                        conn == null
                                            ? <Box sx={{ mt: 1 }}>
                                                <Typography style={{ color: '#1E88E5', textAlign: 'center' }}>У вас ещё нет QR-кода :(</Typography>

                                            </Box>
                                            :

                                            (conn as any)?.Connections?.map((item: any, i: any) => {
                                                var a = Moment(item.Date)
                                                const formatDate = Moment(item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')
                                                console.log("Entered");
                                                // Return the element. Also pass key     
                                                return (
                                                    <div>
                                                        <Accordion sx={{ p: 0 }} expanded={expanded === 'panel' + (i + 1)}/*  defaultExpanded={i === 0 ? true : false}   */ onChange={handleChange('panel' + (i + 1))} style={{ background: '#90caf900', position: 'unset', boxShadow: 'none', padding: '0px' }}
                                                            className="accordion_test"
                                                        >
                                                            <AccordionSummary
                                                                className="accordion_test"
                                                                expandIcon={<ExpandMoreIcon style={{ color: '#ffffff' }} />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                                                    <Typography sx={{ width: '33%', flexShrink: 0 }} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                                                                    {getStatusSymbol(item.IsEnabled, item.ConfId?.Int64, [7, 40, 41, 42, 4040].includes(item.ServerTypeId), item.InternalId.String?.toString(), item.Code, item.Code == '-3', item.ServerTypeId)}


                                                                        {/*   <IconButton style={{ color: props.tg.themeParams.text_color ?? 'black' }} onClick={() => { navigate("/edit", { state: { id: item.Id, name: getStatusName(item.IsEnabled, item.Id, item.Name?.String) } }) }}>
                                                                    <Edit />
                                                                </IconButton> */}
                                                                        <Typography>{getStatusName(item.IsEnabled, item.Id, item.Name?.String)}</Typography>

                                                                    </Typography>

                                                                </div>
                                                            </AccordionSummary>








                                                            <AccordionDetails>

                                                                
                                                                <Grid container spacing={2} mb={1}>
                                                                    <Grid item xs={12}>
                                                                        <Grid
                                                                            container
                                                                            direction="row"
                                                                            justifyContent="start"
                                                                            alignItems="center"


                                                                        >

                                                                            <IconButton
                                                                                className='pay_button'
                                                                                size="small"

                                                                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                                sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                                onClick={() => { onDeleteOpenApprove(item.ServerTypeId == 7 || item.ServerTypeId == 40, item.Id) }}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>


                                                                        </Grid>


                                                                    </Grid>

                                                                </Grid>




                                                                {

item.IsDesc
    ?


    <div>

        {/*       <Grid container spacing={2} mb={2}>
            <Grid item xs={5}>
                <Typography>ID</Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography textAlign={'right'}>{formatDate} MSK</Typography>
            </Grid>
        </Grid>
*/}


        {/*  <Grid container spacing={2} mb={2}>
            <Grid item xs={5}>
                <Typography>Дата создания</Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography textAlign={'right'}>{item.Id}</Typography>
            </Grid>
        </Grid> */}

        <Grid container spacing={2} mb={2}>
            <Grid item xs={5}>
                <Typography>Регион</Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item?.City?.String}</Typography>
            </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
            <Grid item xs={5}>
                <Typography>Сервер</Typography>
            </Grid>
            <Grid item xs={7} >
                <Typography textAlign={'right'}>{item.Domain}</Typography>
            </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
            <Grid item xs={5}>
                <Typography>Статус1</Typography>
            </Grid>
            <Grid item xs={7}>
                <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
            </Grid>
        </Grid>



        {/*    <Grid item xs={12}> */}
        {/*   <pr  value={progress} /> */}
        {/* </Grid> */}
        {/*   <Grid item xs={7}>
                <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
            </Grid> */}


        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                borderRadius: '12px'
            }}

        >

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', color: props.tg.themeParams.text_color ?? '#1E88E5' }} onClick={() => { handleClientsDownload(item.File) }}>
                <DownloadIcon style={{ fontSize: 50 }}></DownloadIcon>
                <Typography>Файл конфигурации</Typography>

            </div>
            {/* <Button
                    sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'none', color: props.tg.themeParams.text_color ?? '#1E88E5', boxShadow: 'none' }} variant="contained"
                    onClick={() => { handleClientsDownload(item.File) }}
                >
                    Файл конфигурации
                </Button> */}
        </Box>

    </div>
    :

    [7, 40, 4040].includes(item.ServerTypeId)
        ?
        <OutlineConnection
            onDeleteOpenClick={onDeleteOpenClick}
            onChangeProtocClick={onChangeProtocClick}
            setLoadingState={props.setLoadingState}
            balance={props.balance}
            item={item}
            data={props.data}
            tg={props.tg}
            test={test}
           /*  onInstructionClick={onInstructionClick} */
            /* setLoadingState={props.setLoadingState} */>

        </OutlineConnection>

        :

        item.ServerTypeId == 41
            ? <TmConnection
                item={item}
                tg={props.tg}
                data={props.data}
              /*   onDeleteOpenClick={onTmDeleteOpenClick}
                onUpdateLimitClick={onUpdateLimitOpenClick} */
                setLoadingState={props.setLoadingState}
                test={test}
               /*  limit={limit}
                onInstructionClick={onInstructionClick} */
            >

            </TmConnection>
            : item.ServerTypeId == 42
                ? <PcConnection
                    item={item}
                    tg={props.tg}
                    data={props.data}
                  /*   onDeleteOpenClick={onTmDeleteOpenClick} */
                    setLoadingState={props.setLoadingState}
                    test={test}
                /*     onUpdateLimitClick={onUpdateLimitOpenClick}
                    limit={limit}
                    onInstructionClick={onInstructionClick} */
                >

                </PcConnection>
                : item.Code != "none"
                    ?
                    <WgConnection
                        onDeleteOpenClick={onDeleteOpenClick}
                        onChangeProtocClick={onChangeProtocClick}
                        setLoadingState={props.setLoadingState}
                        balance={props.balance}
                        item={item}
                        data={props.data}
                        tg={props.tg}
                     /*    onInstructionClick={onInstructionClick} */
                    ></WgConnection>
                    : <NoneConnection
                        onDeleteOpenClick={onDeleteOpenClick}
                        onChangeProtocClick={onChangeProtocClick}
                        setLoadingState={props.setLoadingState}
                        balance={props.balance}
                        item={item}
                        data={props.data}
                        tg={props.tg}

                    ></NoneConnection>

}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        {
                                                            (conn as any).Connections.length - 1 == i
                                                                ? <div></div>
                                                                : < Divider style={{ color: '#edeef0' }} />
                                                        }

                                                    </div>
                                                )
                                            })


                                    }


                                </Box>
                            : <div>Загрузка...</div>
                        }
                    </Box>
            }

        </Box>
    );
}
