import * as React from 'react';
import { getUrl } from '../../auth/getUrl';
import { useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Line } from 'react-chartjs-2';
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";

import { LineChart } from '@mui/x-charts/LineChart';
import { Block } from "@mui/icons-material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import { useNavigate } from 'react-router-dom';


export function PayInfo(props: any) {

    const navigate = useNavigate();
    const [payInfoData, setPayInfoData] = useState<any>({});
    const [payInfo, setPayInfo] = useState<any>({});

    React.useEffect(() => {
        getUrl(`/api/payment_info`)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setPayInfo(data)
            }, error => {
                    navigate("/login")
                }
            );





        getUrl(`/api/payment_info_data`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPayInfoData(data)
            }, error => {
                    navigate("/login")
                }
            );



    }, [])


    
    React.useEffect(() => {
        getUrl(`/api/payment_info`)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setPayInfo(data)
            }, error => {
                navigate("/login")
            }
            );





        getUrl(`/api/payment_info_data?dateStart=`+props.dateStart+"&dateEnd="+props.dateEnd+"&counter="+props.counter)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPayInfoData(data)
            }, error => {
                navigate("/login")
            }
            );



    }, [props.dateStart, props.dateEnd, props.counter])

    return (
        <Box>
            <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10 }} gutterBottom>
                Кол-во подключений <b> - {payInfo?.ConnCount}</b>
            </Typography>
            {
                payInfoData != null && payInfoData.length > 0
                    ?
                    <LineChart
                        xAxis={[{

                            scaleType: 'point', data: payInfoData?.map((x: any) => {
                                var a = new Date(x.Data).toISOString().
                                    replace(/T/, ' ').      // replace T with a space
                                    replace(/\..+/, '')

                                /*  var TheDate = new Date( Date.UTC(2012, 10, 5) );
                                 var a = format(new Date(v), 'dd.MM.yyyy kk:mm:ss') */
                                return a

                            })
                        }]}
                        series={[
                            {
                                data: payInfoData?.map((x: any) => x.ConnCount),

                                color: '#1976d2'
                            },
                        ]}

                        height={300}
                    />
                    : <></>
            }
            <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10 }} gutterBottom>
                Кол-во уникальных пользователей  <b> - {payInfo?.UserCount}</b>
            </Typography>


            {
                payInfoData != null && payInfoData.length > 0
                    ?
                    <LineChart
                        xAxis={[{
                            scaleType: 'point', data: payInfoData?.map((x: any) => {
                                var a = new Date(x.Data).toISOString().
                                    replace(/T/, ' ').      // replace T with a space
                                    replace(/\..+/, '')

                                /*  var TheDate = new Date( Date.UTC(2012, 10, 5) );
                                 var a = format(new Date(v), 'dd.MM.yyyy kk:mm:ss') */
                                return a

                            })
                        }]}
                        series={[
                            {
                                data: payInfoData?.map((x: any) => x.UserCount),
                               
                                color: '#00c853'
                            },
                        ]}

                        height={300}
                    />
                    : <></>
            }
        </Box>

    );
}
