import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { Avatar, Chip, CircularProgress, Grid, Skeleton, Stack, StepConnector, StepIconProps, TextField, Theme, ThemeProvider, Typography, createTheme, outlinedInputClasses, stepConnectorClasses, styled, useTheme } from '@mui/material';
/* import SettingsIcon from '@mui/icons-material/Settings'; */
/* import GroupAddIcon from '@mui/icons-material/GroupAdd'; */
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; import "./stepper_style.css"

import { ES, IT, NL, RU, US } from 'country-flag-icons/react/3x2';
import { useLocation, useNavigate } from 'react-router-dom';
import DevicesIcon from '@mui/icons-material/Devices';

import QrCode2Icon from '@mui/icons-material/QrCode2';

import LinkIcon from '@mui/icons-material/Link';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import { Flag } from '@mui/icons-material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StarIcon from '@mui/icons-material/Star';
import "./styles.css"
import { getUrl } from '../auth/getUrl';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        marginLeft: 22

    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        [`& .${stepConnectorClasses.vertical}`]: {
            marginLeft: 22
        },

    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',
            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',


            backgroundImage:
                'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        marginLeft: 10,
        height: 3,
        border: "none",
        /*  backgroundColor:
             theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', */
        borderRadius: 1,

    },
}));


const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(255, 193, 7) 100%);',
    }),
}));



function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <LocationCityIcon />,
        2: <WifiPasswordIcon />,
        3: <DevicesIcon />,
        4: <LocationOnIcon />,
        5: <VideoLabelIcon />,
        6: <LocationCityIcon />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}

            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}



export default function CreateCode(props: any) {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const location = useLocation();

    const [device, setDevice] = React.useState(1);

    const [protocol, setProtocol] = React.useState(2);

    const [startCity, setStartCity] = React.useState(1);

    const [devices, setDevices] = React.useState([]);

    var onDeviceClick = (id: any) => {



        if (startCity == 2 && (id == 100 || id == 4))
            return
        setDevice(id)

        getAvailableContries(id, startCity, id)

        getTariff(startCity, id, protocol, country)

    }




    var onStartClick = (id: any) => {


        setStartCity(id)
        getAvailableContries(protocol, id, device)
        getDevices(id)

        getTariff(id, device, protocol, country)
    }


    var onTariffClick = (id: any) => {


        var a = tariffs.find((x: any) => x.Id == id)
        setCurrentTariff(a)
    }

    var onProcolClick = (id: any) => {
        setProtocol(id)
        getAvailableContries(id, startCity, device)
        getTariff(startCity, device, id, country)

    }

    const [chips, setChips] = React.useState([]);


    var getFromCountrues = () => {
        getUrl("/api/from_countries")
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    setFromCountries(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }





    var getDevices = (id: any) => {

        getUrl("/api/devices?" + "type=" + id)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {


                    setDevices(result);

                    //getTariff(id, result[0].Id, protocol, country)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }

    var getFromCountrues = () => {
        getUrl("/api/from_countries")
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    setFromCountries(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }


    var getAvailableContries = (id: any, country: any, device: any) => {
        getUrl("/api/available?" + "type=" + id + "&country=" + country + "&device=" + device)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    
                    setCountries(result[0].Flag)
                    setAvailable(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }


    const [isTariffComputing, setIsTariffComputing] = React.useState(false);
    const [tariffs, setTariffs] = React.useState([]);
    const [currentTariff, setCurrentTariff] = React.useState<any>({});

    var getTariff = (startCity: any, device: any, protocol: any, resultCity: any) => {

        //Здесь так потому, что на бэке и фронты перепутаны айди
        // на фронте 2 - аутлайн, 1 - wg
        // на бэке 1 - айтлайн, 2 - wg
        var p = protocol == 1 ? 2 : 1



        setIsTariffComputing(true)
        getUrl("/api/tariff?" + "startCity=" + startCity + "&device=" + device + "&protocol=" + p + "&resultCity=" + resultCity)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {


                    setCurrentTariff(result[0])
                    setTariffs(result);
                    setIsTariffComputing(false)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }


    React.useEffect(() => {

        
        //  getAvailableUsers();
        getFromCountrues();
        /* getTariff(startCity,; */
        getDevices(startCity)

        getTariff(startCity, device, protocol, country)
        getUrl("/api/available?" + "type=" + protocol)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    
                    setCountries(result[0].Flag)
                    setAvailable(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )


        getUrl("/api/parametrs?" + "obj=" + 'chips')
            //fetch("http://localhost:5010" + "/api/parametrs?obj=" + 'chips')
            .then(res => res.json())
            .then(
                (result) => {

                    setChips(result)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff")
    }



    var getFlag = (value: any) => {

        /*         if (value != "NO") {
                    const Flag = Flags[value];
                    return <Flag title={value} style={{ height: 15 }} />
                } */
    }
    const [country, setCountries] = React.useState("ES");
    const [fromCountries, setFromCountries] = React.useState([])
    const [available, setAvailable] = React.useState([]);
    const [name, setName] = React.useState("");

    var onCountryClick = (id: any) => {


        setCountries(id)
        getTariff(startCity, device, protocol, id)
    }


    /*  const devices = [ */
    /*    {
           id: 1,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(1) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <AndroidIcon />
               <div style={{ paddingLeft: 5 }}>Android</div>
           </Box>
       },
       {
           id: 2,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(2) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <AppleIcon />
               <div style={{ paddingLeft: 5 }}>IPhone</div>
           </Box>
 
       },
       {
           id: 3,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(3) }} className={startCity == 2 ? "device_disable" : ""} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>MacOS</div>
           </Box>
       }, {
           id: 4,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(4) }} className={startCity == 2 ? "device_disable" : ""} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>Windows</div>
           </Box>
       },
       {
           id: 5,
           a: <Box display={"flex"} onClick={() => { }} className={"device_disable"} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>IPad</div>
           </Box>
       },
       */
    /*     ] */

    var onCreateCodeClick = () => {


        debugger

        getUrl("/api/create_by_country_by_id?" + "id=" +
            ///* "http://localhost:5010" */  '/api/create_by_country?id='
            + props.userId
            + '&name= test'
            + "&flag=" + country
            + "&device=" + device
            + "&conn_name=" + name
            + "&protocol=" + protocol

            + "&from_country=" + startCity
            + "&tarif=" + currentTariff.Id

        )
            .then(res =>
                navigate("/lk/" + props.userId)
            );


        /*       fetch("http://localhost:5010" + '/api/create_by_country?id='
                  + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )
                  + '&name='
                  + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test")
                  + "&flag=" + country
              )
                  .then(res =>
                      navigate("/")
                  ); */
    }

    var get_flag = (code: any) => {

        return <US style={{ height: 15 }} />


    }


    var handleTextChange = (e: any) => {

        setName(e.target.value)
    }

    /*    function foo() {
           var boxes = document.getElementsByClassName('box');
           var tmp = 0;
           for (var i = 0; i < boxes.length; i++) {
             if ((boxes[i] as any).offsetHeight > tmp) {
               tmp =(boxes[i] as any).offsetHeight;
             }
           }
           for (var z = 0; z < boxes.length; z++) {
             (boxes[z] as any).style.height = tmp + "px";
           }
         } */


    const protocols = [

        {
            id: 2,
            base_id: 2,
            a:



                <Box display={"flex"} onClick={() => { onProcolClick(2) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="Remy Sharp" src={require("../assets/images/outline.png")} />
                            <div style={{ marginLeft: 10 }}>
                                Outline (ShadowSocks)
                            </div>
                        </div>

                        {/*     <DynamicFeedIcon style={{ height: 15 }} /> */}
                        <div style={{}}>

                            {/*                             <ul>
                                <li>{t("outline.text1")}</li>
                                <li>{t("outline.text2")}</li>
                              
                            </ul> */}
                        </div>
                    </div>

                    {/*  <NetworkCheckIcon style={{ height: 15 }} />
            <div style={{ paddingLeft: 5 }}>WireGuard</div> */}
                </Box>


        },
        {
            id: 1,
            base_id: 1,
            a: <Box display={"flex"} onClick={() => { onProcolClick(1) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar alt="Remy Sharp" src={require("../assets/images/wg.png")} />
                        <div style={{ marginLeft: 10 }}>
                            WireGuard
                        </div>
                    </div>

                    {/*     <DynamicFeedIcon style={{ height: 15 }} /> */}
                    <div style={{}}>

                        {/*      <ul>
                            <li>{t("wg.text1")}</li>
                            <li>{t("wg.text2")}</li>
                           
                        </ul> */}
                    </div>
                </div>

            </Box>
        },
    ]

    const customTheme = (outerTheme: Theme) =>
        createTheme({
            /*     palette: {
                    mode: outerTheme.palette.mode,
                }, */
            components: {
                MuiTextField: {

                    styleOverrides: {
                        /*   root: {
                              '--TextField-brandBorderColor': '#E0E3E7',
                              '--TextField-brandBorderHoverColor': '#B2BAC2',
                              '--TextField-brandBorderFocusedColor': '#6F7E8C',
                              '& label.Mui-focused': {
                                  color:   '#1E88E5',
                              },
                          }, */
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
/*                             borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd',
 */                            border: "1px solid",
                            color: '#1E88E5',
                            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'

                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: '#1E88E5',
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: '#1E88E5',
                                /*     borderColor: '#bdbdbd' */
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                        },
                    },
                },

            },
        });

    const onChipClick = (e: any) => {

        setName(e)
    }

    var keyPress = (e: any) => {

        if (e.key == 'Enter') {
            e.target.blur();
        }
    }

    const outerTheme = useTheme()

    const steps = [


        {
            label: "Откуда пользователь",
            description: () => {
                return (
                    <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 9, sm: 8, md: 12 }}>
                        {
                            fromCountries?.map((step: any, index) => {
                                return (

                                    <Grid item xs={3} sm={4} md={4} key={index} style={{ display: 'flex', alignItems: 'stretch', width: '100%' }}>
                                        <Box sx={{
                                            p: 1,
                                            background:
                                                step.Id == startCity
                                                    ? 'rgb(255, 193, 7)'
                                                    : props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)',
                                            borderRadius: '12px',
                                            mb: 1,
                                            border: '1px solid rgb(255, 193, 7)',
                                            display: 'flex',
                                            alignItems: 'stretch',
                                            width: '100%',
                                            color:
                                                step.Id == startCity
                                                    ? 'white'
                                                    : '#1E88E5'
                                        }}>
                                            <Box display={"flex"} onClick={() => { onStartClick(step.Id) }} style={{ borderRadius: '12px', justifyContent: 'center', boxShadow: 'none', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }} >

                                                {/* <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'stretch', width:'100%' }}> */}
                                                {/*  <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center', width:'100%' }}> */}
                                                {/*   <Box>
                                                    {getFlag(step.Flag)}
                                                </Box> */}
                                                <Typography variant="caption">{step.Flag}</Typography>
                                                {/*   <div style={{ }}>
                                                            {step.Country}
                                                        </div> */}
                                                {/*    </div> */}



                                                {/* </div> */}


                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )


                {/* <Stack>
                    {
                        fromCountries?.map((step: any, index) => {


                            return (


                                step.Id == startCity
                                    ?
                                    <Box sx={{ p: 2, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                        <Box display={"flex"} onClick={() => { onStartClick(step.Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {getFlag(step.Flag)}
                                                    <div style={{ marginLeft: 10 }}>
                                                        {step.Country}
                                                    </div>
                                                </div>



                                            </div>


                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={{ p: 2, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                        <Box display={"flex"} onClick={() => { onStartClick(step.Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {getFlag(step.Flag)}
                                                    <div style={{ marginLeft: 10 }}>
                                                        {step.Country}
                                                    </div>
                                                </div>



                                            </div>


                                        </Box>
                                    </Box>



                            )
                        }
                        )


                    } */}

                {/*        {3 == startCity
                        ?
                        <Box sx={{ p: 2, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                            <Box display={"flex"} onClick={() => { onStartClick(3) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    Другое




                                </div>


                            </Box>
                        </Box>
                        :
                        <Box sx={{ p: 2, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                            <Box display={"flex"} onClick={() => { onStartClick(3) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    Другое




                                </div>


                            </Box>
                        </Box>
                    } 
                    
                      </Stack >*/}

            },
        },

        {
            label: "Устройство",
            description: () => {
                return <Stack>
                    {devices.map((step, index) => (
                        (step as any).Id == device
                            ?
                            <Box sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                {/* { (step as any).Name} */}
                                <Box display={"flex"} onClick={() => { onDeviceClick((step as any).Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                                    {getDeviceIcon((step as any).Id)}
                                    {/* <AndroidIcon /> */}
                                    <div style={{ paddingLeft: 5 }}>{(step as any).Name}</div>
                                </Box>
                            </Box>
                            :
                            <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                <Box display={"flex"} onClick={() => { onDeviceClick((step as any).Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                                    {getDeviceIcon((step as any).Id)}
                                    {/* <AndroidIcon /> */}
                                    <div style={{ paddingLeft: 5 }}>{(step as any).Name}</div>
                                </Box>
                            </Box>
                    ))}
                </Stack>

            },
        },

        {
            label: "Протокол",
            description: () => {
                return <Stack>
                    {startCity == 2 || startCity == 100 || startCity == 4 || device == 3 || device == 4
                        ? protocols.filter(x => { return x.id == 2 }).map((step: any, index) => {

                            return (


                                step.id == protocol
                                    ?
                                    <Box sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                        {step.a}
                                    </Box>
                                    :
                                    <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                        {step.a}
                                    </Box>


                                /*       <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                      {step.a}
                                  </Box> */
                            )
                        }
                        )
                        :
                        protocols.map((step: any, index) => {

                            return (


                                step.id == protocol
                                    ?
                                    <Box sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                        {step.a}
                                    </Box>
                                    :
                                    <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                        {step.a}
                                    </Box>


                                /*       <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                      {step.a}
                                  </Box> */
                            )
                        }
                        )
                    }
                </Stack >

            },
        },
        {
            label: "Наименование",
            description: () => {

                return <div style={{ paddingTop: '10px' }}>
                    <Stack>

                        {/* <ValidationTextField value={name}  defaultValue={name} onChange={handleTextChange}></ValidationTextField> */}
                        {/*                         <TextField placeholder='Подключение'       InputProps={{sx: { borderRadius: '12px', borderColor: 'red' } }} value={name} defaultValue={name} style={{ borderRadius: '12px', color:   '#1E88E5' }} 
                        
                        id="test" variant="outlined" onChange={handleTextChange}  focused /> */}
                        <ThemeProvider theme={customTheme(outerTheme)}>
                            <TextField placeholder={"Подключение" + ' #XXXXX'}
                                sx={{ input: { color: '#1E88E5' } }}
                                InputProps={{ sx: { borderRadius: '12px' } }} value={name} defaultValue={name}
                                style={{ borderRadius: '12px', color: '#1E88E5' }}
                                onKeyDown={keyPress}
                                id="test" variant="outlined" onChange={handleTextChange} focused />
                        </ThemeProvider>
                        <div style={{ paddingTop: 10 }}>
                            {

                                chips.map((x: any) => {
                                    return (
                                        <Chip style={{ marginRight: 10 }} label={x.Value} variant="outlined" onClick={() => onChipClick(x.Value)} />
                                    )
                                })
                            }
                        </div>


                    </Stack>
                </div>
            },
        },


        {
            label: "Тарифф",
            code: "tarif",
            description: () => {
                return <Stack>
                    {tariffs.map((step, index) => {
                        return (
                            (step as any).Id == currentTariff?.Id ?
                                <Box onClick={() => { onTariffClick((step as any).Id) }} sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                    {(step as any).Limit} Гб за {(step as any)?.Price} ₽ в месяц
                                </Box>
                                :
                                <Box onClick={() => { onTariffClick((step as any).Id) }} sx={{ p: 1, borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>
                                    {(step as any).Limit} Гб за {(step as any)?.Price} ₽ в месяц
                                </Box>
                        )
                    }
                    )}
                </Stack>

            },
        },

        {
            label: "Регион",
            description: () => {
                return (<Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 9, sm: 8, md: 12 }}>
                    {
                        available?.map((step: any, index) => {
                            return (

                                <Grid item xs={3} sm={4} md={4} key={index} style={{ display: 'flex', alignItems: 'stretch', width: '100%' }}>
                                    <Box sx={{
                                        p: 1,
                                        background:
                                            step.Flag == country
                                                ? 'rgb(255, 193, 7)'
                                                : props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)',
                                        borderRadius: '12px',
                                        mb: 1,
                                        border: '1px solid rgb(255, 193, 7)',
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        width: '100%',
                                        color:
                                            step.Flag == country
                                                ? 'white'
                                                : '#1E88E5'
                                    }}>
                                        <Box display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ borderRadius: '12px', justifyContent: 'center', boxShadow: 'none', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }} >

                                            {/* <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'stretch', width:'100%' }}> */}
                                            {/*  <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center', width:'100%' }}> */}
                                            {/*     <Box>
                                                {getFlag(step.Flag)}
                                            </Box> */}
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="caption">{step.Flag}</Typography>
                                                {/* <Typography variant="caption">{step.Country} </Typography> */}
                                                <Box>
                                                    {
                                                        step.Flag == 'AE' || step.Flag == 'IN'
                                                            ? <StarIcon fontSize='small' className={step.Flag == country ? 'star_country__icon_clicked' : 'star_country__icon'} />
                                                            : <></>
                                                    }
                                                </Box>
                                            </Box>

                                            {/*   <div style={{ }}>
                                                        {step.Country}
                                                    </div> */}
                                            {/*    </div> */}



                                            {/* </div> */}


                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>)


                    /* <Stack>
                    {
                        available.map((step: any, index) => {

                            return (
                                step.Flag == country ?
                                    <Box className={step.ServerId == null ? "device_disable" : ""} sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>

                                        <Box className={step.ServerId == null ? "device_disable" : ""} display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ alignItems: 'center' }} >
                                            {getFlag(step.Flag)}
                                            <div style={{ paddingLeft: 5 }}>{step.Country}</div>
                                            {
                                                step.Flag == 'AE'
                                                    ? <StarIcon fontSize='small' className='star_country__icon_clicked' />
                                                    : <></>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Box className={step.ServerId == null ? "device_disable" : ""} sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, color:   '#1E88E5', border: '1px solid rgb(238, 242, 246)' }}>
                                        <Box display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ alignItems: 'center' }} >
                                            {getFlag(step.Flag)}
                                            <div style={{ paddingLeft: 5 }}>{step.Country}</div>
                                            {
                                                step.Flag == 'AE'
                                                    ? <StarIcon fontSize='small' className='star_country__icon' />
                                                    : <></>
                                            }
                                        </Box>
                                    </Box>
                            )
                        }
                        )
                    }
                </Stack > */}

        },


    ];


    function getTestStepper() {

        var res: any[] = [];

        var i = props.users == false ? 1 : 0
        for (i; i < steps.length; i++) {

            if (props.users == false && steps[i].code == "tarif") {
                continue
            }


            if (steps[i].code == "tarif" && (startCity == 1 || startCity == 3 || (startCity >= 4 && startCity <= 100)) && (device == 1 || device == 2)) {
                continue
            }



            res.push(steps[i]);
        }
        return res
    }


    var getDeviceName = (id: any) => {
        switch (id) {
            case 1:
                return "Android"
            case 2:
                return "IPhone"
            case 4:
                return "Windows"
            case 3:
                return "MacOS"
            default:
                return "Другое"
        }
    }

    var getDeviceIcon = (id: any) => {
        switch (id) {
            case 1:
                return <AndroidIcon />
            case 2:
                return <AppleIcon />
            case 3:
                return <LaptopWindowsIcon />
            case 4:
                return <LaptopWindowsIcon />
            default:
                return <LaptopWindowsIcon />
        }
    }

    return (
        <Box sx={{ width: '100%', mb: 2 }} >

            <Box style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                <Box style={{ color: '#1E88E5', display: 'flex' }}>
                    <div style={{ marginRight: 10, alignItems: 'center' }}>
                        Тариф:
                    </div>
                    {
                        isTariffComputing
                            ? <Skeleton width={'50%'}></Skeleton>
                            :
                            <div>
                                {
                                    startCity == 2 ||
                                        ((startCity == 1 ||
                                            (startCity >= 4 && startCity < 100))
                                            && (device == 3 || device == 4))
                                        ? currentTariff?.Price + " ₽/месяц"
                                        :
                                        (currentTariff?.Price == undefined
                                            ? 3
                                            : currentTariff?.Price)

                                        + " ₽/день"}
                            </div>
                    }
                    {/*  Тариф: {startCity == 2 || (startCity == 1 && (device == 3 || device == 4)) ? currentTariff?.Price + " Руб/месяц" : 3 + " Руб/день"} */}
                </Box>
                <Box style={{ color: '#1E88E5', display: 'flex' }}>
                    <div style={{ marginRight: 10, alignItems: 'center' }}>
                        Девайс:
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {getDeviceIcon(device)} {getDeviceName(device)}
                    </div>

                </Box>

                {
                    country == "IN"
                        ? <Box style={{ color: '#1E88E5', display: 'flex' }}>
                            <div style={{ marginRight: 10, alignItems: 'center' }}>
                                Лимит:
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                100 Гб
                            </div>

                        </Box>
                        : <></>

                }
            </Box>
            <Box style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2, mt: 2 }}>
                <Stepper activeStep={activeStep} connector={<ColorlibConnector />} orientation="vertical" style={{ color: '#ffffff' }} sx={{ mb: 1 }}>
                    {getTestStepper().map((step, index) => {

                        /*   if (index == 0 && props.users == false) {
                              return <></>
                          } */

                        return (
                            <Step key={step.label} sx={{ mt: 1, textAlign:"start" }}/* style={{color:  'red'}} */ >
                                <StepLabel


                                    StepIconComponent={ColorlibStepIcon}

                                >
                                    <div style={{ color: 'red' }}>
                                        {step.label}
                                    </div>
                                </StepLabel>
                                <StepContent>
                                    {step.description()}
                                    <Box sx={{ mb: 2 }} /* style={{background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)'}} */>
                                        <div>
                                            {
                                                index === getTestStepper().length - 1
                                                    ? /* <Button
                                                variant="contained"
                                                onClick={onCreateCodeClick}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Получить код
                                            </Button>
 */
                                                    protocol == 2
                                                        ?

                                                        <  Button
                                                            disabled={isTariffComputing}
                                                            onClick={onCreateCodeClick} className='pay_button' variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                            {"Получить ключ"}
                                                            <LinkIcon sx={{ pl: 1 }} />
                                                        </Button >
                                                        :


                                                        < Button onClick={onCreateCodeClick} className='pay_button' variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                            {"Получить код"}
                                                            <QrCode2Icon sx={{ pl: 1 }} />
                                                        </Button >
                                                    : /* <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Далее
                                </Button> */
                                                    <Button onClick={handleNext} className='pay_button' variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                        {"Далее"}
                                                        <KeyboardArrowRightIcon sx={{}} />
                                                    </Button >


                                            }

                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                                style={{ color: 'red', textTransform: 'none' }}
                                            >
                                                {"Назад"}
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        )
                    }
                    )
                    }
                </Stepper >
            </Box>
            {/*  {
                activeStep === 1 && (
                    <Paper square elevation={0} sx={{ p: 3 }} style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)' }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )
            } */}
        </Box >
    );
}
