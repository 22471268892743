import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Skeleton, Typography } from '@mui/material';

export default function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    props.handleClickOpen();
   
  };


  const handleApprove = () => {
 
    props.handleApprove()
  };
  const handleClose = () => {
    
    props.handleClose();
  };


  React.useEffect(() => { setOpen(props.dialog_open) }, [props.dialog_open]);
  return (


    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white'


        },
      }}
    >
      <DialogTitle id="alert-dialog-title" style={{ color: 'black' }}>
        {"Обновление подключения"}
      </DialogTitle>
      <DialogContent>
        {
          props.isLoading
            ? <Typography variant="h4" sx={{ width: '100%', flexShrink: 0 }} style={{ width: '100%;', color:  'black' }}><Skeleton></Skeleton></Typography>
            : <DialogContentText id="alert-dialog-description" style={{ color:  'black' }}>
              {props.text}
              {/*  Выбранное подключение будет удалено.
          Вместо него будет создано новое подключение протокола Outline */}
            </DialogContentText>
        }
      </DialogContent>

      {
        !props.isOk
          ? <DialogActions>

            <Button onClick={handleClose} disabled={props.isLoading} style={{ color: '#ffffff' }}>Отменить</Button>
            <Button onClick={handleClickOpen} disabled={props.isLoading} style={{ color:  'black' }}>
              Да, продолжить
            </Button>
          </DialogActions>
          : <DialogActions>

            <Button onClick={handleApprove} disabled={props.isLoading} style={{ color:  'black' }}>Отлично</Button>

          </DialogActions>
      }

    </Dialog>

  );
}