import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, IconButton, Skeleton, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import { connect } from 'http2';
import { getUrl } from '../auth/getUrl';
import CreateCode from './CreateCode';



export default function NewCode(props: any) {

    const [data, setData] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const [isConnLoading, setIsConnLoading] = React.useState(true);
    const [isUserInfoLoading, setIsUserInfoLoading] = React.useState(true);

    const navigate = useNavigate();
    const location = useLocation();

/*     React.useEffect(() => {

        getUrl("/api/connections?" + "id=" +     location.state.id       )
     
            .then(res => res.json())
            .then(
                (result) => {
                    setIsConnLoading(false)
                    setData(result.Connections);
                },
                (error) => {

                }
            )

           getUrl("/api/user_info?id=" + location.state.id)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsUserInfoLoading(false)
                    setUserInfo(result);
                },
                (error) => {
                }
            )


    }, []) */




    return (
        /* <CreateCode tg={props.tg}></CreateCode> */
        <Box mb={2} mt={1} sx={{

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }}>
      
                  
                       
                           
      <CreateCode userId = {location.state.userId}/* t={t} tg={props.tg} users={true} */></CreateCode>
                           
            
        </Box>
    );
}