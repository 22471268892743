

/* const base_url = "https://wp.test.whyt.me/" */

/* const base_url = "http://localhost:5020" */
/* const base_url ="https://shai.test.5gst.ru" */


const base_url = "https://pro.whypn.com"
export function getUrl(other:any, tg = null) {
    /* let initDataURLSP = new URLSearchParams(tg.initData);
    var hash = initDataURLSP.get('hash');
    var checkDataString = initDataURLSP.toString()?.replaceAll('&', '\n');
 */
    //return encodeURI(base_url + url + "?hash=" + hash + "&checkDataString="+checkDataString+"&" )

    return fetch(base_url + other, {
        credentials: 'include',
        headers: {
            /*            "Access-Control-Allow-Origin": "*",
                       "Access-Control-Allow-Credentials": "true",
                       "Access-Control-Allow-Headers": "Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Authorization, accept, origin, Cache-Control, X-Requested-With",
                       "Access-Control-Allow-Methods": "POST, OPTIONS, GET, PUT",
                       'Content-type': 'application/json', */
            /*    "Content-Type": "application/x-www-form-urlencoded", */
            /*  'Authorization': `${localStorage.getItem("token")}`, // notice the Bearer before your token */
        },

    })
    /* return encodeURI(base_url + url + "?" ) */
}



