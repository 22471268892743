import React, { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { format } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { setToken } from "../services/token";
import { getUrl } from "../auth/getUrl";
import { useNavigate } from "react-router-dom";
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';


function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
    );
}

function MovingServers(props: any) {
    const navigate = useNavigate();
    const base_url = process.env.REACT_APP_TITLE
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        credentials: "include"
    };

    React.useEffect(() => {

        ;
    }, [])

    const [from, setFrom] = React.useState("");
    const [to, setTo] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);
    const [userVisible, setUserVisible] = React.useState(false);
    const [users, setUsers] = React.useState([]);


    const [isAuthError, setIsAuthError] = React.useState(false);

    const handleFrom = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFrom(event.target.value);
    };
    const handleTo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTo(event.target.value);
    };



    var onChangeClick = () => {
 debugger
        setIsLoading(true)
        setUserVisible(false)

        getUrl("/api/update_conenctions_by_server?old_server=" + from + "&new_domain=" + to)

            .then(res => res.json())
            .then(
                (result:any) => {

                    debugger
                    setUsers(result)
                    setUserVisible(true)

                    setIsLoading(false)

                }, error => {
                    navigate("/login")
                })


    };


    return (
        <div style={{ textAlign: 'start' }}>
            <Typography component="h1" variant="h5">
                Домен сервера
            </Typography>

            <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Откуда"
                name="email"
                onChange={handleFrom}
                autoComplete="email"
                autoFocus
            />
            <TextField
                margin="normal"

                fullWidth
                id="email"
                label="Куда"
                name="email"
                onChange={handleTo}
                autoComplete="email"
                autoFocus
            />
            <Box style={{ display: 'flex', gap: "10px" }}>
                <Button
                    disabled
                    type="submit"
                    fullWidth
                    variant="contained"
                /*   sx={{ mt: 3, mb: 2 }} */
                /*  onClick={onLoginClick} */
                >
                    Получить список пользователей
                </Button>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    /*   sx={{ mt: 3, mb: 2 }} */
                    onClick={onChangeClick}
                >
                    Поменять
                </Button>
            </Box>

            {
                isLoading
                    ?
                    <Box style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                        <GradientCircularProgress />
                    </Box>
                    : <></>
            }

            {
                userVisible 
                ?<>
                <Typography>Пользователи:</Typography>
                {
                    users.map((x:any) => {
                        return <Typography>{x.ChatId}</Typography>
                    })
                }</>
                :<></>
            }



        </div>
    );
}

export default MovingServers;
